<template>
  <div class='indexbox'>
    <div class="searchbox">
      <el-tabs v-model="active" @tab-click='tabbox'>
        <el-tab-pane label="IMPORT" name="1"></el-tab-pane>
        <el-tab-pane label="LOG" name="2"></el-tab-pane>
      </el-tabs>
    </div>

    <div class="tablebox" v-if="active == 1">
      <div class="bodybox">
        <div class="topbox">
          <div class="left">
            <div class="left">
              <p>Step 1</p>
              <p>Download Our Inventory List Template File</p>
              <p>This File Has Correct Customer Headings DEAR Needs To Import Your Inventory List.</p>
            </div>
          </div>
          <el-button @click="downloaddata" :loading='btn_loadinga'>Download Excel Template</el-button>
        </div>
      </div>
      <div class="bodybox">
        <div class="topbox" style="margin-bottom:20px;">
          <div class="left">
            <div class="left">
              <p> Step 2</p>
              <p>Copy Your Inventory List Into The Template</p>
              <p>Export Your Inventory List From Your Old System As Excel File. Using Excel Or Another Spreadsheet Editor,
                Copy And Paste Your Inventory List From The Exported File Into The Downloaded Template.
                Make Sure The Inventory List Data You Copy Matches The Column Headings Provided In The Template.</p>
            </div>
          </div>
        </div>
        <div class="listbox" style="margin-bottom:20px;">
          <div class="titbox">
            <p>Required Fields</p>
            <p>Each Product Is Identified By A Unique ProductCode.</p>
          </div>
          <div class="tabbox">
            <p>FIELD NAME</p>
            <p>WHAT IS IT?</p>
          </div>
          <div class="tablist">
            <p> Status </p>
            <p>The status of the transfer order that needs to be imported </p>
          </div>
          <div class="tablist">
            <p>From</p>
            <p>
              The Warehouse That Executes Inventory Issuance. If The Bin Needs To Be Selected, The Format Is "Warehouse:
              Bin.
              <br>
              Note: There Must Be A Blank Between The ":" And Bin
            </p>
          </div>
          <div class="tablist">
            <p>To</p>
            <p>
              The Warehouse That Performs The Stock Receipt. If The Bin Needs To Be Selected, The Format Is "Warehouse:
              Bin.
              <br>
              Note: There Must Be A Blank Between The ":" And Bin
            </p>
          </div>
          <div class="tablist">
            <p>Stock Sent Date</p>
            <p>An Optional Field For The Date When The Stock Is Required At The Destination Location.</p>
          </div>
          <div class="tablist">
            <p>Stock Received Date</p>
            <p>Date The Stock Is Dent From The Source Location. This Field Is Mandatory For Stock Transfers With Transit.
            </p>
          </div>
          <div class="tablist">
            <p>SKU</p>
            <p>Product SKU Identifying The Product To Load Stock On Hand For.</p>
          </div>
          <div class="tablist">
            <p>TransferQuantity</p>
            <p>Enter A (Transfer) Quantity For Each Product.</p>
          </div>
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="Optional Fields" name="2">
              <p class="tabtit">You can fill in an optional fields if you have them</p>
              <div class="tabbox">
                <p>OPTIONAL FIELD</p>
                <p>WHAT IS IT?</p>
              </div>
              <div class="tablist">
                <p>Reference</p>
                <p>An Optional Stock Transfer Reference Field.</p>
              </div>
              <div class="tablist">
                <p>Product Name</p>
                <p>The Name Of The Product</p>
              </div>
              <div class="tablist">
                <p>Comment</p>
                <p>Comments On The Product In The Transfer Order</p>
              </div>
              <div class="tablist">
                <p>Batch/Serial NO</p>
                <p>For Product With Batch/Serial NO Is Required, Otherwise, It's Optuinal</p>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <el-alert
          title="Do not change the column headingd in the template file.These need to be unchanged for the import to work in the next step."
          type="error" :closable=false></el-alert>
      </div>
      <div class="bodybox" style="border:none;">
        <div class="topbox">
          <div class="left">
            <div class="left">
              <p>Step 3</p>
              <p>Import The Updated Template File</p>
              <p>The File You Import Must Be An Excel File. The Name Of Your File Should End With .xls .xlsx.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bodybox" v-if="show">
        <div class="draggable" v-loading='loading'>
          <el-table :data="tableData" stripe>
            <el-table-column prop="Status" label="Status" width='120' align='center' fixed />
            <el-table-column prop="FromLocation" label="From" width='120' align='center' fixed />
            <el-table-column prop="ToLocation" label="To" width='120' align='center' fixed show-overflow-tooltip />
            <el-table-column prop="Reference" label="Reference" width='100' align='center' show-overflow-tooltip />
            <el-table-column prop="DepartureDate" label="Stock Sent Date" sortable width='250' align='center'
              show-overflow-tooltip />
            <el-table-column prop="CompletionDate" label="Stock Received Date" sortable width='180' align='center'
              show-overflow-tooltip />
            <el-table-column prop="SKU" label="Product SKU" width='130' align='center' show-overflow-tooltip />
            <el-table-column prop="ProductName" label="ProductName" width='130' align='center' show-overflow-tooltip />
            <el-table-column prop="TransferQuantity" label="TRANSFER QUANTITY" width='180' align='center'
              show-overflow-tooltip />
            <el-table-column prop="Comments" label=" COMMENTS" width='120' align='center' show-overflow-tooltip />
            <el-table-column prop="Batch/Serial" label="Batch/Serial NO" width='140' align='center'
              show-overflow-tooltip />
            <el-table-column prop="error" label="Error Description" min-width="150" align='center' show-overflow-tooltip>
              <template slot-scope="scope">
                <img src="../../assets/img/error@2x.png" @click="opendata(scope.row)" width="25" height="25" alt=""
                  v-if="scope.row.error">
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bodybox" style="border:none;">
        <div class="upfile" v-hasPermi="['toi']">
          <el-form ref="form" :model="form" :rules="rules" class="formbox">
            <el-form-item prop="file">
              <el-upload :limit='1' class="upload-demo" action='' drag :auto-upload="false" accept=".xlsx, .xls"
                :on-change="Uploadchange" :on-remove='clearFiles' :file-list="fileList">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Drag The File Here, Or<em>Click Upload</em></div>
                <div style="color:red" slot="tip">Tip: Only Import Is Allowed “xls” Or “xlsx” Format File！</div>
              </el-upload>
            </el-form-item>
            <el-form-item class="btnbox">
              <el-button type="primary" @click.native.prevent="loadOrders" :loading='btn_loading' v-if="!show">Verify
                File</el-button>
              <el-button v-hasPermi="['toi']" class="download" type="primary" :loading='loadinga'
                v-if="active == 1 && show" @click="importOrders">Import Into DEAR</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <div class="tablebox" v-if="active == 2">
      <div class="listsbox">
        <div class="draggable" v-loading='loadingl'>
          <el-table :data="tableDatalog" stripe>
            <el-table-column prop="email" label="Upload By" width='300' align='center' fixed />
            <el-table-column prop="create_time" label="Upload Date" sortable width='300' align='center' fixed />
            <el-table-column prop="file_name" label="Uploaded File Name" min-width='300' align='center'
              show-overflow-tooltip />
            <el-table-column prop="status" label="Upload Status" width='200' align='center' show-overflow-tooltip>
              <template slot-scope="scope">
                <p>{{ scope.row.status == 0 ? 'Fail' : scope.row.status == 1 ? 'Success' : '' }}</p>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination @current-change='pagechage' background layout="total,prev, pager, next" v-show="total > 0"
          :current-page.sync="page" :page-size="10" :total="total" />
      </div>
    </div>
  </div>
</template>
<script>
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      active: '1',
      activeName: '2',
      // 导入
      show: false,
      fileList: [],
      form: {
        file: '',
      },
      rules: {
        file: [
          { required: true, trigger: "blur", message: 'Please Upload The File' }
        ]
      },
      tableData: [],
      loading: false,
      loadinga: false,
      btn_loading: false,
      btn_loadinga: false,

      total: 0,
      page: 1,
      tableDatalog: [],
      loadingl: false,
    };
  },
  computed: {
    ...mapGetters(['api_id']),
  },
  created() {
    this.getlist()
    if (sessionStorage.getItem("transfer_activeName")) {
      this.active = sessionStorage.getItem("transfer_activeName")
    }
  },
  methods: {
    tabbox(tab) {
      this.active = tab.name
      sessionStorage.setItem("transfer_activeName", tab.name)
    },
    // 导入 上传file
    Uploadchange(file) {
      this.form.file = file.raw
    },
    clearFiles() {
      this.fileList = []
      this.form.file = ''
      this.tableData = ''
      this.show = false
    },
    //loadOrders
    loadOrders() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.btn_loading = true
          let data = new FormData();
          data.append("file", this.form.file);
          data.append("api_id", this.$store.state.user.api_id);
          data.append("token", getToken());
          this.$post({
            url: "/app/assembly_orders/importTransferList",
            upload: true,
            data,
            success: (res) => {
              this.$message({
                message: 'Finished',
                type: 'success'
              });
              this.tableData = res.data
              setTimeout(() => {
                this.show = true
                this.btn_loading = false
              }, 1500);
            },
            tip: () => {
              setTimeout(() => {
                this.btn_loading = false
              }, 1500);
            },
          })
        }
      });
    },
    //导入模板下载
    downloaddata() {
      this.btn_loadinga = true
      this.$post({
        url: "/app/assembly_orders/getExcelUrl",
        success: (res) => {
          let url = res.data.url2
          window.open(url)
          setTimeout(() => {
            this.btn_loadinga = false
          }, 1200);
        },
        tip: () => {
          setTimeout(() => {
            this.btn_loadinga = false
          }, 1200);
        },
      })
    },
    importOrders() {
      this.loadinga = true
      let data = new FormData();
      data.append("file", this.form.file);
      data.append("api_id", this.$store.state.user.api_id);
      data.append("token", getToken());
      this.$post({
        url: "/app/assembly_orders/importTransferOrders",
        upload: true,
        data,
        success: (res) => {
          this.$message({
            message: 'Success',
            type: 'success'
          });
          this.form = {
            file: ''
          }
          this.fileList = []
          setTimeout(() => {
            this.pagechage(1)
            this.show = false
            this.loadinga = false
          }, 1500);
        },
        tips: (err) => {
          console.log(12, err);
          this.$notify({
            title: 'tips',
            message: err.msg,
            type: 'error',
            duration: 0,
          });
          setTimeout(() => {
            this.pagechage(1)
            this.loadinga = false
          }, 1500);
        },
      })
    },
    opendata(data) {
      this.$alert(data.error, {
        showClose: false
      });
    },
    getlist() {
      this.loadingl = true
      this.$post({
        url: "/app/import_log/pageList",
        data: {
          api_id: this.$store.state.user.api_id,
          page: this.page,
          type: 2,
        },
        success: (res) => {
          this.page = res.data.current_page
          this.tableDatalog = res.data.data
          this.total = res.data.total
          setTimeout(() => {
            this.loadingl = false
          }, 200);
        },
        tip: () => {
          this.loadingl = false
        },
      })
    },
    pagechage(page) {
      this.page = page
      this.tableDatalog = []
      this.getlist()
    },
  },
}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  height: 100%;
  .searchbox {
    width: 100%;
    padding: 10px 30px 0;
    background: #fff;
    border-bottom: 1px solid #babdcd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /deep/.el-tabs__header {
      margin-bottom: 0;
      .el-tabs__nav-wrap::after {
        display: none;
      }
    }
  }
  .tablebox {
    width: 100%;
    padding: 20px;
    .bodybox {
      width: 100%;
      padding: 20px;
      background: #fff;
      border-bottom: 1px solid #dbdbdb;
      .topbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #999999;
        font-size: 14px;
        p:nth-child(1) {
          color: #333333;
          font-weight: 550;
        }
        p:nth-child(2) {
          color: #666666;
          line-height: 2;
        }
      }
      /deep/.el-button--default {
        color: #19cbbf;
        background-color: #fff;
        border-color: #19cbbf;
        border-radius: 4px;
      }
      .listbox {
        width: 100%;
        margin-top: 15px;
        padding: 10px 15px;
        background: #f1f6fb;
        border-radius: 10px;
        .titbox {
          width: 100%;
          color: #999999;
          font-size: 14px;
          p:nth-child(1) {
            color: #333333;
            font-weight: 550;
          }
          p:nth-child(2) {
            padding: 10px 0 20px;
          }
        }
        .tabbox {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #333333;
          font-size: 14px;
          font-weight: 550;
          padding: 8px 0;
          border-bottom: 1px solid #dbdbdb;
          p:nth-child(1) {
            width: 20%;
          }
        }
        .tablist {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          font-weight: 550;
          padding: 8px 0;
          line-height: 1.6;
          p:nth-child(1) {
            width: 20%;
            color: #333333;
            font-size: 14px;
          }
          p:nth-child(2) {
            width: 80%;
            color: #666666;
            font-size: 12px;
          }
        }
        /deep/.el-collapse {
          border: none;
          .el-collapse-item__header {
            background: none;
            color: #333333;
            font-size: 14px;
            font-weight: 550;
          }
          .el-collapse-item__content {
            padding-bottom: 0;
          }
          .el-collapse-item__wrap {
            border-bottom: none;
            background: none;
            .tabtit {
              margin-bottom: 20px;
              color: #999999;
              font-size: 14px;
            }
          }
        }
      }
    }
    .bodybox:nth-child(1) {
      border-radius: 10px 10px 0 0;
    }
    .bodybox:last-child {
      border-bottom: none;
      border-radius: 0 0 10px 10px;
      .upfile {
        padding: 10px 0 0;
      }
      /deep/.el-upload-dragger {
        width: 100%;
      }
    }
    .listsbox {
      width: 100%;
      padding: 20px;
      background: #fff;
    }
  }
  .formbox {
    /deep/.el-form-item {
      margin-bottom: 30px !important;
      .el-form-item__content {
        width: 100%;
        .el-button {
          width: 100%;
          height: 48px;
          background: $color;
          border-radius: 5px;
          border-color: $color;
        }
      }
    }
  }
}
</style>
